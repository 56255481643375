<div class="content-body">
  <div class="row-container">
    <div class="text-col">
      <h2>Datenschutzhinweise</h2>
      <br />
      <p>
        Der Schutz Ihrer persönlichen Daten hat für die Deutsche Telekom AG
        (Telekom RealEstate) einen hohen Stellenwert. Es ist uns wichtig, Sie
        darüber zu informieren, welche persönlichen Daten erfasst werden, wie
        diese verwendet werden und welche Gestaltungsmöglichkeiten Sie dabei
        haben.
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >1. Welche Daten werden erfasst, wie werden sie verwendet und wie
          lange werden sie gespeichert?
        </strong>
      </p>
      <br />
      <p>
        a) Erforderliche Verarbeitungen bei der Erbringung des digitalen
        Dienstes (Art. 6 Abs. 1b DSGVO, §25 Abs. 2 Nr. 2 TDDDG)
      </p>
      <span style="display: block; padding-left: 20px">
        ● Bei der Absendung des Kontaktformulars werden, vorbehaltlich Ihrer
        Einwilligung, folgende Daten gespeichert: <br />

        <p style="display: block; padding-left: 40px">
          ∘ Vor- und Nachname <br />
          ∘ Kontaktdaten (Ggf. Postadresse, Telefonnummer, E-Mail-Adresse)
          <br />
          ∘ Standortdaten der Liegenschaft <br />
          ∘ Ihre Bestätigung die Daten zu speichern und dass Sie die
          Datenschutz- und Einwilligungserklärung gelesen haben und akzeptieren
          <br />
        </p>
        <span style="display: block; padding-left: 20px">
          Zweck der Verarbeitung Ihrer Daten ist die Kontaktaufnahme im Rahmen
          Ihres immobilienspezifischen Anliegens (u. a. Baulast, Dienstbarkeit,
          Löschbewilligung, Kaufanfrage, Öffentlich-rechtliches Verfahren,
          Sonstiges) und dessen Bearbeitung. Ihre Daten werden von Personen und
          Organisationen sowie Dienstleistern der Telekom verarbeitet, um mit
          Ihnen in Kontakt zu treten und Ihnen Angebote zu Ihrer Anfrage zu
          unterbreiten.
        </span>
      </span>
      <br />
      <p>
        b) Verarbeitungen bei der Erbringung des digitalen Dienstes, die auf
        Basis des berechtigten Interesses erfolgen (Art. 6 Abs. 1 f DSGVO, §25
        Abs. 2 Nr. 2 TDDDG)
      </p>
      <span style="display: block; padding-left: 20px">
        Wenn Sie unseren digitalen Dienst nutzen, verzeichnen unsere Server den
        Domain-Namen oder die IP- Adresse Ihres Endgerätes sowie weitere Daten,
        wie z. B. die angefragten Inhalte, den Antwort-Code, Generische User-ID,
        welche nicht rückverfolgbar ist, aufgerufene Unterseiten. Sämtliche
        Daten sind nicht personenbezogene Daten. Die protokollierten Daten
        werden für Zwecke der Datensicherheit, insbesondere zur Abwehr von
        Angriffsversuchen auf unseren Server verwendet. Die Daten werden zudem
        zum Monitoring des Webseite Traffics genutzt. Dieses wird durch
        statistische Auswertungen anonymisierter Datensätze durchgeführt.
      </span>
      <br />

      <p>
        <strong
          >2. Datenkontrolle bei den eingesetzten Social Media Plug-ins bzw.
          Links zu Social Media Plattformen
        </strong>
      </p>
      <br />
      <p>
        Einige Seiten enthalten Buttons von Social Media Netzwerken (wie z. B.
        Facebook, Google, Instagram, X [Twitter], Pinterest, Xing, TikTok oder
        LinkedIn), mit denen Sie Ihren Freunden und Bekannten die Angebote der
        Deutsche Telekom AG (Telekom RealEstate) empfehlen können.
      </p>
      <br />
      <p>
        In unserem digitalen Dienst verwenden wir nur die Piktogramme des
        jeweiligen Social Media Netzwerkes. Erst mit einem Klick auf das
        Piktogramm werden Sie auf die Unternehmens-Seite auf der jeweiligen
        Social Media Plattform geleitet. Die Social Media Plattformen sowie die
        Fremdinhalteanbieter, die über die Piktogramme erreicht werden können,
        erbringen diese Dienste und die Verarbeitung ihrer Daten in eigener
        Verantwortung.
      </p>
      <br />
      <p>
        Durch das Aktivieren des Social Media Plug-ins bzw. Links über das
        Piktogramm, auch zum Teilen von Inhalten, (Art. 6 Abs. 1 a DSGVO) können
        folgende Daten an die Social Media Anbieter übermittelt werden:
        IP-Adresse, Browserinformationen, Betriebssystem, Bildschirmauflösung,
        installierte Browser-Plug-ins, vorherige Seite, wenn Sie einem Link
        gefolgt sind (Referrer), die URL der aktuellen Seite, etc.. <br />
        Beim nächsten Aufruf werden die Social Media Plug-ins erneut im
        voreingestellten inaktiven Modus bereitgestellt, so dass bei einem
        erneuten Besuch sichergestellt ist, dass keine Daten übermittelt werden.
        <br />
        Weitere Informationen zu Social Media Plug-ins zum Umfang und zu den
        Zwecken der jeweiligen Datenverarbeitung sowie weitere
        datenschutzrelevante Informationen finden Sie in den
        Datenschutzerklärungen des jeweiligen Verantwortlichen.
      </p>

      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >3. Wird mein Nutzungsverhalten ausgewertet, z. B. für Werbung oder
          Tracking?
        </strong>
      </p>

      <br />
      <p>
        Wir möchten, dass Sie unsere digitalen Dienste gerne nutzen und unsere
        Produkte und Dienste in Anspruch nehmen. Damit Sie die Produkte finden,
        die Sie interessieren und wir unseren digitalen Dienst nutzerfreundlich
        ausgestalten können, analysieren wir pseudonymisiert Ihr
        Nutzungsverhalten. Im Rahmen der gesetzlichen Regelungen werden
        Nutzungsprofile angelegt. Nachfolgend informieren wir Sie allgemein über
        die verschiedenen Zwecke der Verarbeitungen. Über die Abfrage
        „Einwilligung in die Datenverarbeitung“, die beim Aufruf unseres
        digitalen Dienstes erscheint, haben Sie die Möglichkeit den
        Verarbeitungen zuzustimmen oder sie in Teilen oder Ganz abzulehnen.
        Verarbeitungen, die zur Erbringung des digitalen Dienstes erforderlich
        sind, (siehe Erläuterung oben unter 1.) können nicht abgelehnt werden.
      </p>
      <br />
      <p>
        <strong>a) Basis-Funktionalität des digitalen Dienstes</strong><br />
        Diese Verarbeitungen sind immer aktiv und notwendig, damit der digitale
        Dienst richtig funktioniert.
      </p>

      <span style="display: block; padding-left: 20px">
        ● Funktional <br />
        <span style="display: block; padding-left: 17px">
          Diese Verarbeitungen sind notwendig, damit Sie durch den digitalen
          Dienst navigieren und wesentliche Funktionen nutzen können. Sie
          ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online-Shop
          und den Zugriff auf gesicherte Bereiche des digitalen Dienstes. Zudem
          dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns
          verwendet werden, um unseren digitalen Dienst stetig für Sie
          weiterzuentwickeln. Rechtsgrundlage für diese Verarbeitungen ist §25
          Abs. 2 Nr. 2 TDDDG, Art. 6 Abs. 1b DSGVO bzw. bei Drittstaaten Art. 44
          ff. DSGVO.
        </span>
      </span>
      <br />
      <span style="display: block; padding-left: 20px">
        ● Analytisch <br />
        <span style="display: block; padding-left: 17px">
          Es werden keine solchen Cookies genutzt.
        </span>
      </span>
      <br />
      <p>
        <strong>b) Optionale Verarbeitungen </strong><br />
        Es werden keine solchen Cookies genutzt.
      </p>

      <br />
      <p>
        <strong
          >c) Marketing durch Deutsche Telekom AG (Telekom RealEstate) </strong
        ><br />
        Es werden keine solchen Cookies genutzt.
      </p>
      <br />
      <p>
        <strong>d) Analyse durch unsere Partner</strong><br />
        Diese Verarbeitungen helfen uns und unseren Partnern, Services zu
        verbessern. Wir nutzen in unseren digitalen Diensten Cookies und
        Analyse-Technologien, die uns helfen, unsere Dienste zu verbessern.
        Diese Technologien werden auch von unseren Partnern verwendet, die
        eigenständig oder in gemeinsamer Verantwortung mit der Deutsche Telekom
        AG (Telekom RealEstate) ihre Dienstleistungen anbieten.
        <br />
        Hierbei werden Daten und Informationen zu Analysezwecken an Partner
        übermittelt, dort teils zu eigenen Analysezwecken der Partner
        weiterverarbeitet und mit Daten Dritter zusammengeführt. Das bedeutet,
        dass Informationen über Ihre Nutzung des digitalen Dienstes erfasst und
        verarbeitet werden, um unsere Produkte und Angebote besser zu verstehen
        und zu verbessern. Rechtsgrundlage für diese Verarbeitungen ist §25 Abs.
        1 TDDDG, Art. 6 Abs. 1a DSGVO bzw. bei Drittstaaten Art. 49 Abs. 1a
        DSGVO.
      </p>
      <br />
      <p>
        <strong>e) Spotify</strong><br />
        Auf der „Über uns“ Seite der Telekom Real Estate Webseite ist im unteren
        Bereich die Rubrik „Jetzt reinhören“ enthalten. Durch Anklicken des
        dortigen Buttons werden Sie direkt an Spotify weitergeleitet. Sie können
        sich jederzeit über die Datenverarbeitung von Spotify AB, Regeringsgatan
        19, 111 53 Stockholm, Schweden unter
        <a href="https://www.spotify.com/de/legal/privacy-policy/"
          >https://​www​.​spo​ti​fy​.​com​/​de​/​le​gal​/​pri​va​cy​-​po​li​cy​/</a
        >
        informieren und dieser widersprechen.
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >4. Wo finde ich weitere Informationen zum Datenschutz bei der
          Telekom?
        </strong>
      </p>
      <br />

      <p>
        Weitere Informationen, auch zum Datenschutz in speziellen Produkten,
        erhalten Sie unter
        <a href="https://www.telekom.de/datenschutzhinweise">
          https://www​.​te​le​kom​.​de​/​da​ten​schutz​hin​wei​se
        </a>
        und unter
        <a href="http://www.telekom.com/datenschutz">
          http://www​.​te​le​kom​.​com​/​da​ten​schutz
        </a>
        .
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>5. Welche Rechte habe ich?</strong>
      </p>
      <br />
      <p>Sie haben das Recht,</p>
      <p>
        a) <strong>Auskunft</strong> zu verlangen zu Kategorien der
        verarbeiteten Daten, Verarbeitungszwecken, etwaigen Empfängern der
        Daten, der geplanten Speicherdauer (Art. 15 DSGVO);
      </p>
      <br />
      <p>
        b) die <strong>Berichtigung</strong> bzw. Ergänzung unrichtiger bzw.
        unvollständiger Daten zu verlangen (Art. 16 DSGVO);
      </p>
      <br />
      <p>
        c) eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu
        <strong>widerrufen </strong>(Art. 7 Abs. 3 DSGVO);
      </p>
      <br />
      <p>
        d) einer Datenverarbeitung, die aufgrund eines berechtigten Interesses
        erfolgen soll, aus Gründen zu <strong>widersprechen, </strong>die sich
        aus Ihrer besonderen Situation ergeben (Art 21 Abs. 1 DSGVO), unter
        Angabe dieser Gründe jederzeit für die Zukunft zu widersprechen. Einer
        Datenverarbeitung für Zwecke der Direktwerbung können Sie jederzeit ohne
        Angabe dieser Gründe widersprechen (Art. 21 Abs. 2, 3 DSGVO);
      </p>
      <br />
      <p>
        e) in bestimmten Fällen im Rahmen des Art. 17 DSGVO die
        <strong>Löschung</strong> von Daten zu verlangen - insbesondere soweit
        die Daten für den vorgesehenen Zweck nicht mehr erforderlich sind bzw.
        unrechtmäßig verarbeitet werden, oder Sie Ihre Einwilligung gemäß oben
        (c) widerrufen oder einen Widerspruch gemäß oben (d) erklärt haben;
      </p>
      <br />
      <p>
        f) unter bestimmten Voraussetzungen die
        <strong>Einschränkung</strong> von Daten zu verlangen, soweit eine
        Löschung nicht möglich bzw. die Löschpflicht streitig ist (Art. 18
        DSGVO);
      </p>
      <br />
      <p>
        g) auf <strong>Datenübertragbarkeit, </strong>d.h. Sie können Ihre
        Daten, die Sie uns bereitgestellt haben, in einem gängigen
        maschinenlesbaren Format, wie z.B. CSV, erhalten und ggf. an andere
        übermitteln (Art. 20 DSGVO;)
      </p>
      <br />
      <p>
        h) sich bei der zuständigen <strong>Aufsichtsbehörde</strong> über die
        Datenverarbeitung zu beschweren (für Telekommunikationsverträge:
        Bundesbeauftragter für den Datenschutz und die Informationsfreiheit; im
        Übrigen: Landesbeauftragte für den Datenschutz und die
        Informationsfreiheit Nordrhein-Westfalen).
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>6. An wen gibt die Telekom meine Daten weiter?</strong>
      </p>
      <br />
      <p>
        <strong>An Auftragsverarbeiter, </strong>
        das sind Unternehmen, die wir im gesetzlich vorgesehenen Rahmen mit der
        Verarbeitung von Daten beauftragen, Art. 28 DSGVO (Dienstleister,
        Erfüllungsgehilfen). Die Telekom bleibt auch in dem Fall weiterhin für
        den Schutz Ihrer Daten verantwortlich. Wir beauftragen Unternehmen
        insbesondere in folgenden Bereichen: IT, Vertrieb, Marketing, Finanzen,
        Beratung, Kundenservice, Personalwesen, Logistik, Druck.
      </p>
      <p>
        <strong>An Kooperationspartner, </strong>
        die in eigener Verantwortung Leistungen für Sie bzw. im Zusammenhang mit
        Ihrem Telekom-Vertrag erbringen. Dies ist der Fall, wenn Sie Leistungen
        solcher Partner bei uns beauftragen oder wenn Sie in die Einbindung des
        Partners einwilligen oder wenn wir den Partner aufgrund einer
        gesetzlichen Erlaubnis einbinden.
      </p>
      <p>
        <strong>Aufgrund gesetzlicher Verpflichtung:</strong>
        In bestimmten Fällen sind wir gesetzlich verpflichtet, bestimmte Daten
        an die anfragende staatliche Stelle zu übermitteln.
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong>7. Wo werden meine Daten verarbeitet?</strong>
      </p>
      <br />
      <p>
        Ihre Daten werden in Deutschland und im europäischen Ausland
        verarbeitet. Im Übrigen gilt: Findet eine Datenverarbeitung in
        Drittstaaten statt, geschieht dies, soweit Sie hierin ausdrücklich
        eingewilligt haben oder es für unsere Leistungserbringung Ihnen
        gegenüber erforderlich ist oder es gesetzlich vorgesehen ist (Art.49
        DSGVO). Eine Verarbeitung Ihrer Daten in Drittstaaten erfolgt nur,
        soweit durch bestimmte Maßnahmen sichergestellt ist, dass hierfür ein
        angemessenes Datenschutzniveau besteht (z. B. Angemessenheitsbeschluss
        der EU-Kommission oder sog. geeignete Garantien, Art. 44ff. DSGVO, (vgl.
        <a
          [href]="'https://www.telekom.de/datenschutzhinweise/download/060.pdf'"
          target="_blank"
          >https://www.telekom.de/datenschutzhinweise/download/060.pdf</a
        >).
      </p>
      <br />
      <p>
        <em> </em>
      </p>
      <p>
        <strong
          >8. Wer ist verantwortlich für die Datenverarbeitung? Wer ist mein
          Ansprechpartner, wenn ich Fragen zum Datenschutz bei der Telekom habe?
        </strong>
      </p>
      <br />
      <p>
        Datenverantwortliche ist die Deutsche Telekom AG (Telekom RealEstate)
        Sternengasse 14-16 50676 Köln. Bei Fragen können Sie sich an unseren
        Kundenservice wenden oder an unseren Datenschutzbeauftragten, Herrn Dr.
        Claus D. Ulmer, Friedrich-Ebert-Allee 140, 53113 Bonn,
        <a href="mailto:datenschutz@telekom.de"
          >da​ten​schutz​&#64;​te​le​kom​.​de</a
        >.
      </p>

      <br />
      <p>Stand der Datenschutzhinweise 01.09.2024</p>
    </div>
    <div class="image-container">
      <img src="assets/images/undraw_dataprotection.svg" />
    </div>
  </div>
</div>
